<template>
  <fullscreen-overlay-frame :title="device?.name"
                            :icon="getIcon(device?.type)"
                            color="primary"
                            centered
                            closable
                            maximizable
                            :hide-actions-slot="!isSiteAdmin"
                            @close="close"
                            @maximize="maximize"
                            @minimize="minimize">
    <template v-slot:content>
      <v-alert v-if="!device?.gatewayOnline"
               text
               class="font-size-03 mb-5"
               border="left"
               icon="portable_wifi_off"
               type="warning">
        <div>{{ $t('device-dialog.alert.gateway-offline') }}</div>
        <v-btn outlined small
               color="warning"
               class="mt-3 float-right"
               @click="showHelp">
          {{ $t('gateway-status-card.help-btn') }}
        </v-btn>
      </v-alert>

      <v-row>
        <!-- configuration card for MecMeter devices at first position -->
        <v-col cols="12" :md="fullscreen ? '6' : '12'"
               v-if="device?.type === 'meter-mec'">
          <device-card-mec-meter :device="device"/>
        </v-col>

        <!-- special cards for the infrared controller (Alfanar only) -->
        <v-col cols="12" :md="fullscreen ? '6' : '12'"
               v-if="device?.type === 'infrared-controller'">
          <device-card-i-r-controller-configuration :device="device"/>
        </v-col>
        <v-col cols="12" :md="fullscreen ? '6' : '12'"
               v-if="device?.type === 'infrared-controller'">
          <device-card-i-r-controller :device="device"/>
        </v-col>

        <!-- sensors and actuator cards -->
        <v-col cols="12" :md="fullscreen ? '6' : '12'">
          <!-- specific cards for nuki devices -->
          <device-card-nuki-lock v-if="device?.type === 'nuki-lock'"
                                 :device="device"/>
          <device-card-nuki-opener v-else-if="device?.type === 'nuki-opener'" :device="device"/>

          <device-card-kaadas-lock v-else-if="device?.type === 'kaadas-lock'" :device="device"/>

          <!-- specific cards for the watermodule (Alfanar only) -->
          <div v-else-if="device?.type==='net-watermodule'">
            <water-control-tile :waterControlStatus="device"/>
            <water-control-animation :waterControlStatus="device"/>
          </div>

          <!-- all other device types -->
          <generic-device-card v-else
                               :device="device"/>
        </v-col>

        <!-- general device information -->
        <v-col cols="12" :md="fullscreen ? '6' : '12'">
          <content-card :title="$t('device-dialog.infos.title').toString()"
                        icon="info">
            <template v-slot:content>
              <v-list class="pa-0">
                <!-- device name -->
                <v-list-item class="list-item">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">label</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content v-if="editName">
                    <v-text-field outlined dense
                                  hide-details
                                  ref="device-name-input"
                                  v-model="device.name"/>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-title class="font-size-03"
                                       v-text="device?.name ?? $t('device-list.unsupported-device')"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-html="$t('device-dialog.infos.name')"/>
                  </v-list-item-content>
                  <v-list-item-action v-if="editName">
                    <v-btn icon
                           :loading="loading.changeDeviceName"
                           @click="updateName">
                      <v-icon>check</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action v-else-if="isSiteAdmin">
                    <v-btn icon
                           @click="changeName">
                      <v-icon class="non-flip">edit</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <!-- device type -->
                <v-list-item class="list-item">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">category</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03"
                                       v-text="device?.typeLabel"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-html="$t('device-dialog.infos.type')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- device status -->
                <v-list-item class="list-item">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined"
                            color="success"
                            v-if="device?.online && device?.gatewayOnline">
                      contactless
                    </v-icon>
                    <v-icon class="material-icons-outlined non-flip"
                            color="error"
                            v-else>
                      offline_bolt
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03"
                                       v-text="device?.online && device?.gatewayOnline ? $t('device.online') : $t('device.offline')"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-html="$t('device-dialog.infos.status')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- Battery -->
                <v-list-item v-if="device?.hasOwnProperty('battery-level')"
                             class="list-item">
                  <v-list-item-icon>
                    <!-- special case: devices with no battery level but batteryCritical flag -->
                    <v-icon v-if="device['battery-level'] === null && device['battery-low'] === true" :class="'material-icons-outlined'"
                            color="warning">
                      battery_alert
                    </v-icon>
                    <v-icon v-else-if="device['battery-level'] === null && device['battery-low'] === false" :class="'material-icons-outlined'">
                      battery_full
                    </v-icon>
                    <!-- all other cases -->
                    <v-icon v-else
                            :class="isNonFlipIcon(getBatteryIcon(device['battery-level'])) ? 'material-icons-outlined non-flip' : 'material-icons-outlined'"
                            :color="device['battery-level'] <= 0 ? 'error' : ''">
                      {{ getBatteryIcon(device['battery-level']) }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03"
                                       v-text="getBatteryLevelString(device)"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-html="$t('device-dialog.infos.battery')"/>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else
                             class="list-item">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">electrical_services</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03"
                                       v-text="$t('device-dialog.infos.grid')"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-html="$t('device-dialog.infos.power-supply')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- device last changed -->
                <v-list-item v-if="device?.lastChanged"
                             class="list-item">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined non-flip">schedule</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03 always-ltr-text"
                                       v-text="getFormattedTimeString(device?.lastChanged)"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-html="$t('device-dialog.infos.last-changed')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- device key -->
                <v-list-item v-if="device?.deviceKey">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined non-flip">vpn_key</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03 always-ltr-text"
                                       v-text="device.deviceKey"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-html="$t('device-dialog.infos.device-key')"/>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </content-card>

        </v-col>

        <v-col cols="12" :md="fullscreen ? '6' : '12'">
          <zwave-configuration :device="device"></zwave-configuration>

          <!-- favourites checkbox -->
          <v-checkbox :label="$t('device-dialog.show-on-home')"
                      :disabled="loading.isFavoriteDevice"
                      v-if="isSiteAdmin && $isFavourable(device?.type)"
                      v-model="isFavorite"
                      @change="updateFavoriteAssignment"/>


          <v-checkbox label="Battery Alert"
                      v-if="isSiteAdmin && device?.hasOwnProperty('battery-level')"
                      v-model="device.alert_battery_active"
                      @change="updateBatteryAlert"
                      :disabled="!isGatewayOnline"
                      class="alert-checkbox"
          />
        </v-col>
      </v-row>
    </template>

    <template v-slot:actions>
      <v-btn outlined
             v-if="isSiteAdmin && device.deviceWhitelistKey !== 'MEC_METER'"
             class="font-weight-bold action-button"
             color="primary"
             @click="removeDevice">
        <v-icon left
                class="material-icons-outlined">
          delete
        </v-icon>
        {{ $t('device-dialog.remove-device') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import deviceTypeIcons from "@/config/deviceTypeIcons.json";
import ContentCard from "@/templates/components/ContentCard";
import formats from "@/scripts/formats";

import DeviceCardNukiLock from "@/templates/components/devices/DeviceCardNukiLock";
import DeviceCardNukiOpener from "@/templates/components/devices/DeviceCardNukiOpener";
import GenericDeviceCard from "@/templates/components/devices/GenericDeviceCard.vue";
import {isNonFlipIcon} from "@/i18n";
import DeviceCardMecMeter from "@/templates/components/devices/DeviceCardMecMeter.vue";
import DeviceCardIRController from "@/templates/components/devices/irController/DeviceCardIRController.vue";
import DeviceCardIRControllerConfiguration from "@/templates/components/devices/irController/DeviceCardIRControllerConfiguration.vue";
import WaterControlTile from "@/templates/components/waterControl/WaterControlTile.vue";
import WaterControlAnimation from "@/templates/components/waterControl/WaterControlAnimation.vue";
import DeviceCardKaadasLock from "@/templates/components/devices/DeviceCardKaadasLock.vue";
import ZwaveConfiguration from "@/templates/components/devices/ZwaveConfiguration.vue";

export default {
  name: 'DeviceDialog',

  components: {
    ZwaveConfiguration,
    DeviceCardKaadasLock,
    WaterControlAnimation, WaterControlTile,
    DeviceCardIRController,
    DeviceCardIRControllerConfiguration,
    DeviceCardMecMeter,
    GenericDeviceCard,
    DeviceCardNukiLock,
    DeviceCardNukiOpener,
    ContentCard,
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: function () {
    return {
      loading: {
        changeDeviceName: false,
        isFavoriteDevice: false
      },
      fullscreen: false,
      device: this.data.device,
      editName: false,
      showWarning: false,
      timer: null,
      deviceUpdateInterval: 4000,
      isFavorite: false,
      isSiteAdmin: null,
      demoSelectedActivity: 0,
      isGatewayOnline: true,
      onlineCheckTimer: null
    }
  },

  methods: {
    isNonFlipIcon,
    init() {
      this.isSiteAdmin = this.$rhAuth.isSiteAdmin()
      this.showWarning = false
      this.editName = false
      this.device = this.data.device

      this.getFavoriteDevice()

      this.timer = setInterval(() => {
        this.updateDevice()
      }, this.deviceUpdateInterval)

      this.checkIsGatewayOnline()
      this.onlineCheckTimer = setInterval(() => {
        this.checkIsGatewayOnline()
      }, 10000)
    },

    /**
     * returns a matching icon for a passed device type
     *
     * @param type
     * @returns {string}
     */
    getIcon(type) {
      return deviceTypeIcons[type]?.icon ?? 'widgets'
    },

    /**
     * returns a matching icon for the battery level of the device
     *
     * @param level
     */
    getBatteryIcon(level) {
      switch (true) {
        case (level === null):
          return 'battery_unknown'
        case (level === -1):
          return 'battery_alert'
        case (level < 10):
          return 'battery_0_bar'
        case (level < 30):
          return 'battery_1_bar'
        case (level < 40):
          return 'battery_2_bar'
        case (level < 50):
          return 'battery_3_bar'
        case (level < 60):
          return 'battery_4_bar'
        case (level < 70):
          return 'battery_5_bar'
        case (level <= 90):
          return 'battery_6_bar'
        case (level > 90):
          return 'battery_full'
        default:
          return 'battery_unknown'
      }
    },

    /**
     * returns a String representing the battery level of the device
     */
    getBatteryLevelString(device) {
      if (device['battery-level'] === null && device['battery-low'] === true) {
        return this.$t('device-dialog.battery-level.sufficient')
      } else if (device['battery-level'] === null && device['battery-low'] === false) {
        return this.$t('device-dialog.battery-level.low')
      } else if (device['battery-level'] === null) {
        return this.$t('device-dialog.battery-level.unknown')
      } else if (device['battery-level'] <= 0) {
        return this.$t('device-dialog.battery-level.empty')
      } else {
        return device['battery-level'] + " %"
      }
    },

    checkIsGatewayOnline() {
      this.$rhRequest.sendGet({
        endpoint: 'gateway'
      }, (response) => {
        this.isGatewayOnline = Boolean(response?.data?.data[0]?.online)
      }, (error) => {
        console.error(error)
        this.isGatewayOnline = false
      })
    },

    /**
     * returns a formatted date time string
     *
     * @param timestamp
     * @returns {string}
     */
    getFormattedTimeString(timestamp) {
      return formats.formatDate(timestamp, this.$t('app.date-time-format.long'))
    },

    /**
     * sets the dialog in name edit mode
     */
    changeName() {
      this.editName = true
      this.$nextTick(() => { // wait until refs are defined
        this.$refs['device-name-input'].focus()
      })
    },

    /**
     * updates the device name
     */
    updateName() {
      if (!/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/.test(this.device.name)) {
        this.loading.changeDeviceName = true
        let self = this
        this.$rhRequest.sendPost({
          endpoint: 'device/' + this.device['encryptedId'] + '/update',
          data: {
            name: self.device.name
          }
        }, (resp) => {
          let device = resp.data.data
          if (device?.name) {
            self.device.name = device.name
          }
          self.loading.changeDeviceName = false
          self.editName = false
        }, (err) => {
          console.error(err)
          self.$root.bisatoast.error({message: this.$t('app.generic-error')})
          self.loading.changeDeviceName = false
          self.editName = false
        })
      } else {
        this.$root.bisatoast.error({message: this.$t('device-dialog.update-name.invalid-chars')})
      }
    },

    /**
     * updates isFavorite
     */
    getFavoriteDevice() {
      this.loading.isFavoriteDevice = true
      this.$rhRequest.sendGet({
        endpoint: "devices/get-app-favorites-device-ids"
      }, (resp) => {
        let ids = resp?.data?.data
        this.loading.isFavoriteDevice = false
        this.isFavorite = !!(Array.isArray(ids) && ids.includes(this.device?.id))
      }, (err) => {
        console.error(err)
        this.loading.isFavoriteDevice = false
      })
    },

    updateBatteryAlert() {
      let options = {
        endpoint: 'device-alert/set',
        data: {
          "deviceId": this.device.encryptedId,
          "type": "battery",
          "active": this.device.alert_battery_active
        }
      }
      this.$rhRequest.sendPost(
          options,
          () => {
            // nothing to do here
          }, (err) => {
            console.error(err)
          }
      )
    },

    /**
     * adds or removes the current device to/from the favorites group
     */
    updateFavoriteAssignment() {
      let endpoint = 'devices/remove-device-from-favorites'
      if (this.isFavorite) {
        endpoint = 'devices/add-device-to-favorites' // box is being checked
      }

      this.loading.isFavoriteDevice = true
      this.$rhRequest.sendGet({
        endpoint: endpoint,
        params: {
          deviceId: '' + this.device?.id
        }
      }, () => {
        if (this.isFavorite) {
          this.$root.bisatoast.success({message: this.$t('device-dialog.add-favorites.success'), showCloseBtn: true})
        } else {
          this.$root.bisatoast.success({message: this.$t('device-dialog.remove-favorites.success'), showCloseBtn: true})
        }
        this.loading.isFavoriteDevice = false
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
        this.loading.isFavoriteDevice = false
      })
    },

    /**
     * opens the remove device dialog
     */
    removeDevice() {
      this.$root.bisadialog.toggle('device', false)
      this.$root.bisadialog.toggle('removeDevice', true, {device: this.device})
      this.$root.bisadialog.callDialogInit('removeDevice')
    },

    /**
     * updates the device information
     */
    updateDevice() {
      let self = this
      this.$rhRequest.sendGet({
        endpoint: 'devices/get',
        params: {
          deviceIds: '' + this.device?.id
        }
      }, (response) => {
        let device = response?.data?.data
        if (device && !self.editName) {
          self.device = device[Object.keys(device)[0]]
          self.device.curatedZWaveConfigs = self.device?.curatedZWaveConfigs.sort((a, b) => a.order - b.order)
        } else {
          console.warn('Device not found.')
        }
      }, (error) => {
        console.error(error)
      })
    },

    /**
     * show gateway offline help dialog
     */
    showHelp() {
      this.$root.bisadialog.toggle('gatewayOfflineHelp')
    },

    /**
     * maximize dialog
     */
    maximize() {
      this.fullscreen = true
      this.$emit('maximize')
    },

    /**
     * minimize dialog
     */
    minimize() {
      this.fullscreen = false
      this.$emit('minimize')
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('device')
      clearInterval(this.timer)
      this.timer = null
      clearInterval(this.onlineCheckTimer)
      this.onlineCheckTimer = null
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    clearInterval(this.onlineCheckTimer)
    this.onlineCheckTimer = null
    this.device = null
  }

};
</script>

<style lang="scss" scoped>
.alert-checkbox {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>

